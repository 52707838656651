<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-row>
                            <b-col lg="12" sm="12">
                                <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                                    <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                                        <b-overlay :show="unitLoad">
                                            <b-row style="border-bottom:1px solid #ddd; margin-bottom:10px">
                                                <b-col xl="12" lg="12" sm="12">
                                                    <b-table-simple bordered>
                                                        <thead class="text-white bg-success">
                                                            <tr>
                                                                <th class="text-center mytd" >{{ $t('globalTrans.sl_no') }}</th>
                                                                <th class="mytd" scope="col">{{ $t('bsri_demandDIS.farmer_name') }}</th>
                                                                <th class="mytd" scope="col">{{$t('bsri_demandDIS.farmer_nid_number')}}</th>
                                                                <th class="mytd" scope="col">{{ $t('bsri_demandDIS.crop_name') }}</th>
                                                                <th class="mytd" scope="col">{{ $t('bsri_demandDIS.method_of_cultivation') }}</th>
                                                                <th class="mytd" scope="col">{{ $t('bsri_demandDIS.total_land_quantity') }}</th>
                                                                <th class="mytd" scope="col">{{ $t('bsri_demandDIS.inspection_comment') }}</th>
                                                                <th class="mytd" scope="col">{{ $t('globalTrans.action') }}</th>
                                                            </tr>
                                                        </thead>
                                                        <b-tbody>
                                                            <b-tr v-for="(info, index) in finalApprovedList.list" :key="index">
                                                                <b-td class="text-center mytd">{{$n(index + 1)}}</b-td>
                                                                <b-td class="mytd">{{ $i18n.locale === 'bn' ? info.name_bn : info.name }}</b-td>
                                                                <b-td class="mytd">{{ $n(info.nid) }}</b-td>
                                                                <b-td class="mytd">{{ info.crop_name_id }}</b-td>
                                                                <b-td class="mytd">{{ $i18n.locale === 'bn' ? info.cultivation_method_name_bn : info.cultivation_method_name }}</b-td>
                                                                <b-td class="mytd">{{ $n(info.land_qty) }}</b-td>
                                                                <b-td class="mytd">{{ $i18n.locale === 'bn' ? info.comment_bn : info.comment }}</b-td>
                                                                <b-td class="mytd">
                                                                    <b-form-group>
                                                                        <!-- <b-form-checkbox
                                                                            class="mt-2"
                                                                            @input="checked(index,info)"
                                                                            :id="'approve'+index"
                                                                            v-model="info.selected"
                                                                            :value="1"
                                                                            :unchecked-value="2"
                                                                        >
                                                                        </b-form-checkbox> -->
                                                                         <b-form-checkbox
                                                                            class="mt-2"
                                                                            v-model="info.selected"
                                                                            :value="1"
                                                                            :unchecked-value="2"
                                                                        >
                                                                        </b-form-checkbox>
                                                                    </b-form-group>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr v-if="nodata">
                                                                <td colspan="5" class="text-center">
                                                                    <span class="text-black">{{ $t('globalTrans.noDataFound') }}</span>
                                                                </td>
                                                            </b-tr>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <ValidationProvider name="Comment's" vid="comment_en" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="comment_en"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.approval_comment_en')}} <span class="text-danger">*</span>
                                                        </template>
                                                            <b-form-textarea
                                                            id="comment_en"
                                                            v-model="finalApprovedList.comment"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-textarea>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <ValidationProvider name="Comment's" vid="comment_bn" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="comment_bn"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.approval_comment_bn')}} <span class="text-danger">*</span>
                                                        </template>
                                                            <b-form-textarea
                                                            id="comment_bn"
                                                            v-model="finalApprovedList.comment_bn"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-textarea>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                            </b-row>
                                            <div class="row">
                                                <div class="col-sm-3"></div>
                                                <div class="col text-right">
                                                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                    &nbsp;
                                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.cancel') }}</b-button>
                                                </div>
                                            </div>
                                        </b-overlay>
                                    </b-form>
                                </ValidationObserver>
                            </b-col>
                        </b-row>
                        <!-- <pre>{{ item }}</pre> -->
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ApprovalSubsidizedFarmerStore } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
    props: ['item'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data () {
        return {
            nodata: false,
            unitLoad: false,
            saveBtnName: this.$t('globalTrans.save'),
            updateBtnName: this.$t('globalTrans.update'),
            finalApprovedList: {
                fiscal_year_id: 0,
                mill_info_id: 0,
                subzone_office_id: 0,
                center_id: 0,
                unit_id: 0,
                comment: '',
                comment_bn: '',
                list: []
            }
        }
    },
    created () {
        if (this.item) {
           this.getEditingData(this.item)
        }
    },
    watch: {
    },
    mounted () {
        core.index()
        flatpickr('#datepicker', {})
    },
    computed: {
    },
    methods: {
        // checked (key, info) {
        //     if (info.selected === 1) {
        //         this.finalApprovedList.list.push(info)
        //     }
        //     if (info.selected === 2) {
        //         this.finalApprovedList.splice(key, 1)
        //     }
        // },
        getEditingData (item) {
             this.unitLoad = true
            this.finalApprovedList.field_inspection_id = item.id
            this.finalApprovedList.fiscal_year_id = item.fiscal_year_id
            this.finalApprovedList.mill_info_id = item.mill_info_id
            this.finalApprovedList.subzone_office_id = item.subzone_office_id
            this.finalApprovedList.center_id = item.center_id
            this.finalApprovedList.unit_id = item.unit_id
            item.inspection_details.filter(inspectedDetail => {
                const cultivationMethodObj = this.$store.state.incentiveGrant.commonObj.cultivationMethodList.find(item => item.value === inspectedDetail.demand_collection.cultivation_id)
                const obj = {
                    far_general_info_id: inspectedDetail.far_general_infos.id,
                    demand_collection_id: inspectedDetail.demand_collection_id,
                    name: inspectedDetail.far_general_infos.name,
                    name_bn: inspectedDetail.far_general_infos.name_bn,
                    nid: inspectedDetail.far_general_infos.nid_no,
                    cultivation_method_name: typeof cultivationMethodObj !== 'undefined' ? cultivationMethodObj.text_en : '',
                    cultivation_method_name_bn: typeof cultivationMethodObj !== 'undefined' ? cultivationMethodObj.text_bn : '',
                    land_qty: inspectedDetail.demand_collection.total_land,
                    comment: inspectedDetail.comment,
                    comment_bn: inspectedDetail.comment_bn,
                    selected: 1
                }
                 this.unitLoad = false
                this.finalApprovedList.list.push(obj)
            })
        },
        async saveData () {
            this.unitLoad = true
            // this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, ApprovalSubsidizedFarmerStore, this.finalApprovedList)
            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)

            if (result.success) {
                this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })
                this.unitLoad = false
                this.$bvModal.hide('modal-5')
            } else {
                this.unitLoad = false
                this.$refs.form.setErrors(result.errors)
            }
        }
    }
}
</script>
